import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';


import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";

import 'react-circular-progressbar/dist/styles.css';

const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  KmlLayer,
} = require("react-google-maps");

const styles = theme => ({})

const MapWithAKmlLayer = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCf7LhcFBj3Pkm1Lt9gsxATWScnQDFIZF4&v=3&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `500px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props =>
    <GoogleMap
      defaultZoom={9}
      defaultCenter={{ lat: 19.34, lng: -99.16 }}
    >
      <KmlLayer
        url="https://sgt.fte.mx/uploads/mapa/doc.kml"
        
        options={{ preserveViewport: true }}
     /> 
    </GoogleMap>
  );



class Cobertura extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 



    componentDidMount(){
        this.setState({'waiting':true})
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };

    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }


    render() {
        const {  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            <div>

               <h1>Cobertura nacional FTE </h1> 

                <MapWithAKmlLayer />

                
            </div>
        )
    }

}


export default withStyles(styles)(Cobertura);