import React, {useContext, List, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { numberWithCommas } from '../../functions';
import  { UserContext }  from '../../contexts/UserContext';
import {  readOffers, d_openInfo,d_createUser,d_cardList,d_payment,d_removeCard, readUserInfo, updateUserInfo, d_oxxorefr,d_paynetrefr,d_conekta_createUser,d_conekta_payment} from '../../components/dataController';
//import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, Tabs, Tab, Modal, Checkbox } from "@material-ui/core";
//import RecargaItem from "./RecargaItem";
import AlertMessage from '../../components/fields/AlertMessage';
import ButtonField from '../../components/fields/ButtonField';
import TextField from '../../components/fields/TextField';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Fade from '@material-ui/core/Fade';
//import Section from '../components/Section';
import AddCard from '../../components/AddCard'
import AddCardConekta from '../../components/AddCardConekta'
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';
import fileDownload from 'js-file-download'
import ReactWhatsapp from 'react-whatsapp';



import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';


var user = null;


const openId = null;

class Recargas extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
        dataUser : {} ,
        offers : [],
        offersF : [],
        pago_open : false,
        cardModal : false,
        deleteModal : false,
        oxxoModal : false,
        nameModal : false,
        openId : null,
        cards : [],
        selCard : null,
        cardWaiting : false,
        numbers : [],
        selectedNum : null,
        nombre : '',
        apellidos : '',
        email : '',
        checkout : false,
        subscriptionMode : true,
        open : false,
        metodoPago : 1
    } 

    

    static contextType = UserContext;
    
    componentDidMount(){

       // DEV 
       // window.OpenPay.setId('mnypp7qydn7msvpm5uii');
      //  window.OpenPay.setApiKey('pk_ed5a406538014dd29102791791d39465');
       // window.OpenPay.setSandboxMode(true);

     
        //Production
        window.OpenPay.setId('mlcotkjrngo7l6keycg6');
        window.OpenPay.setApiKey('pk_da94c22afc9948daa046114bb08c8bd6');
        window.OpenPay.setSandboxMode(false);


            var str = window.location.search;
            var objURL = {};
        
            str.replace(
                new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
                function( $0, $1, $2, $3 ){
                    objURL[ $1 ] = $3;
                }
            );
            if(objURL['msisdn']){

                var txt = objURL['msisdn']
                if(txt.length==12 ){
                   txt = txt.slice(2);
                }
                this.setState({numberto:txt}, ()=>{
                    this.searchNumber()
                })
                
            };
     

      

        /*
       this.context.loginInfo(()=>{

            //console.log(this.context.user,"context",this.context.user.numbers)

            var options = [];

     

            for (let index = 0; index < this.context.user.numbers.length; index++) {
                options.push({value:this.context.user.numbers[index].msisdn,label:this.context.user.numbers[index].msisdn + "   --    " + this.context.user.numbers[index].alias})
                
            }

            //console.log(this.context.user.numbers[0].msisdn,"numero seleccionado")
            this.setState({numbersArr:options,waiting:false,selectedNum:
                {value: this.context.user.numbers[0].msisdn,label:this.context.user.numbers[0].msisdn +  "   --    " + this.context.user.numbers[0].alias} })
            
            this.getNumberInfo(this.context.user.numbers[0].msisdn).then( item => {
                console.log(item,'item fg')
                if(item){
                    this.getOfs(item.responseSubscriber.primaryOffering.offeringId)
                }
                
            })
            
        }); */

        //this.context.tokenize()
        //Sandbox

    }

    handleChange(evt) {
        const numberto= (evt.target.validity.valid) ? evt.target.value : this.state.numberto;
        
        this.setState({ numberto });
      }

    searchNumber = () =>{
        console.log(this.state.numberto,'number')
        this.setState({waitingOffers:true,offers:[],offersF:[],selectedNum:{value: this.state.numberto, label:'numerop c'}})    
        this.getNumberInfo(this.state.numberto).then( item => {
            if(item && item.status!='error'){
                
                this.getOfs(item.responseSubscriber.primaryOffering.offeringId);
            }else{
                this.setState({message : item.description,severity:"error",msg:true,waitingOffers:false})
            }
            
        })
        
    }

     async getNumberInfo (msisdn){
        let dataAltan  = await readUserInfo(msisdn);
        return dataAltan;
    }

    getImage = (id) =>{
        console.log(id,"id")
        toPng(document.getElementById(id))
        .then(function (dataUrl) {
            const link = document.createElement('a')
            link.download = 'referencia.png'
            link.href = dataUrl
            link.click()
        });
    }

    getOfs(offer){
       // console.log(offer,'offer to go',this.context.user.openId)
        let offerid = offer //this.context.user.ALTAN.responseSubscriber.primaryOffering.offeringId;
            this.getOffers(offerid);
            //console.log('userinfo',this.context.user.openId)
            /*if(this.context.user.openId==undefined){
                //console.log({name:this.context.user.nombre + " " + this.context.user.apellidos ,email:this.context.user.username, userId: this.context.user._id}, 'creando usuario')
                if(this.context.user.nombre==undefined || this.context.user.nombre==''){
                    this.setState({nameModal:true});
                }else{
                    console.log('creando en ofertas')
                    this.createUser({name:this.context.user.nombre + " " + this.context.user.apellidos ,email:this.context.user.username, userId: this.context.user._id});
                }
                
            }else{
                this.setState({openId:this.context.user.openId});
                this.cardList();
            }*/
            

           // this.createUserConekta({name:this.state.numberto  ,email:'act@fte.net.mx', userId:  new Date().getTime()});
    }

 
    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    getOffers(offerid){
        console.log(offerid,"offerid")
        readOffers(offerid).then(data=>{
            this.setState({offers:data,waitingOffers:false,offersF:data})
            console.log(data,"data")
        })
    }

    openpay = (item) =>{
       // console.log(item._id,"item proc",this.state.offers)
        let  items  = this.state.offers;
        let selItem = null

        let offerFinal = [];
       
            for (let index = 0; index < items.length; index++) {
                if(items[index]._id == item._id){
                    //items[index].selected = true;
                    selItem = items[index];
                    selItem.selected = true
                    offerFinal.push(selItem)
                } else{
                    items[index].selected = false;
                }
            }


          if(item.id==999999){
              offerFinal = this.state.offers;
          }  
      
        
        //console.log(items,"ITEMS YA")
        this.setState({pago_open:true,offersF : offerFinal,selItem:selItem})
    }



    clearItems = ()=>{
        this.setState({selItem:null,selCard:null})
        var item = {id:999999}
        this.openpay(item);
    }

    updateNames = (item) => {
        console.log(this.state.nombre,this.state.apellidos,"asdasd")
        if(this.state.nombre!='' && this.state.apellidos!=''){
            //this.context.user.nombre
           

            this.updateUser(this.state.nombre,this.state.apellidos)
            //this.createUser({name:this.state.nombre + " " + this.state.apellidos ,email:this.state.username, userId: this.state.user._id});

            this.handleNameModal()
        }else{
            alert('Complete los datos por favor.')
        }
    }

    async updateUser(nombre,apellidos){
        let userInfo = {
            nombre: this.state.nombre,
            apellidos : this.state.apellidos
        }
        await updateUserInfo(userInfo).then( res =>{
            console.log('regreso update de usuario')
            this.context.user.nombre = nombre;
            this.context.user.apellidos = apellidos;
            
        })
    }
    
    cardSelect = (item) =>{
        // console.log(item._id,"item proc",this.state.offers)
         let  items  = this.state.cards;
         let selItem = null
         
         for (let index = 0; index < items.length; index++) {
             if(items[index].id == item.id){
                 items[index].selected = true;
                 selItem = items[index]
                 
             } else{
                 items[index].selected = false;
             }
         }
         //console.log(items,"ITEMS YA")
         this.setState({cards : items,selCard:selItem})
     }

   async openInfo(data){
     await d_openInfo(data).then( res =>{
        console.log(res)
     })
   }

   async createUser(data){
     await d_createUser(data).then(res =>{
         console.log(res.data.id,'open id nuevo')
        this.setState({openId:res.data.id});
        return res.data.id;
     })
   }

   async createUserConekta(data){
    await d_conekta_createUser(data).then(res =>{
        console.log(res,'conekta  id nuevo')
       this.setState({conektaId:res.conektaId});
       
    })
  }


   cardList = async() =>{


    let data = {clientId:this.state.openId}
    await d_cardList(data).then(res =>{
        if(res==null) { res = []}
        this.setState({cards:res,cardModal:false})
    })

   }

    addOXXO = async() =>{
        console.log(this.state.selItem, "SELECTED ITEM");

    let precio = this.state.selItem.PRECIOPUBLICO


        
    var data = {
        unit_price : precio,
        name :  "Usuario FTE",
        username :  "act@fte.net.mx",
        email : this.state.email,
        phone : this.state.selectedNum.value,
        sku : this.state.selItem.OFFERID
    }

    console.log(data,"oferta a mandar sku")

    this.setState({oxxoModal:true});

     await d_oxxorefr(data).then(res =>{
        var referencia =  res.charges.data[0].payment_method.reference;
        var barcode = res.charges.data[0].payment_method.barcode_url;
         this.setState({price:precio,referencia:referencia,barcode:barcode})
        console.log(res,'referencia oxxo')
    })
}

addPAYNET = async() =>{

    var err = [];
    if(this.state.nombre=='' || this.state.nombre.length<3 ){
        err.push('Nombre requerido')
    }

    if(this.state.email=='' || this.state.email.length<3 ){
        err.push('Email requerido')
    }
    console.log(err,"errs")
    if(err.length==0){

        d_createUser({name:this.state.nombre + " " + this.state.apellidos ,email:this.state.email, userId: '000000'}).then( rest => {
                
                console.log(rest,"rest" )

            if(rest.data.id){
                this.generatePaynet(rest.data.id)
            }else{
                alert("Error generando referencia")
            }
            
        
        })

   
    }else{
        alert("Verifique sus datos personales")
        //this.setState({message : 'hola',severity:"error",msg:true, waiting :false,waitingOffers:false})
    }

}

    generatePaynet = async (openId) => {

        let precio = this.state.selItem.PRECIOPUBLICO

        var data = {
            unit_price : precio,
            name : this.state.nombre || "" + this.state.selectedNum.value,
            username : "act@fte.net.mx",
            email : this.state.email,
            phone : this.state.selectedNum.value,
            sku : this.state.selItem.OFFERID,
            clientId : openId
        }
        
        console.log(data,"oferta a mandar sku")
        
        this.setState({paynetModal:true,referencia:"Generando..."});
        
         await d_paynetrefr(data).then(res =>{
            var referencia =  res.payment_method.reference;
            var barcode = res.payment_method.barcode_url;
            this.setState({price:precio,referencia:referencia,barcode:barcode})
            console.log(res,'referencia paynet')
        })
    }

  
    handleOxxoModal = () =>{
    this.setState({oxxoModal:false});
   }
   handlePaynetModal = () =>{
    this.setState({paynetModal:false});
   }

   handleCardModal = () =>{
    this.setState({cardModal:false});
   }

   handleDeleteModal = () =>{
    this.setState({deleteModal:false});
   }

   handleCompleteModal = () =>{
    this.setState({completeModal:false});
   }

   handleNameModal = () =>{
    this.setState({nameModal:false});
   }

 
   addCard = () =>{
       this.setState({cardModal:true})
   }

   

   completeF = () =>{
       this.setState({completeModal:false,selCard:null,selected:false,selItem:null});
      // window.location = '/dash'
       
   }

   removeCardModal = (itemId)=>{
    this.setState({deleteModal:true,itemToDel:itemId})
   }
   removeCard = () =>{

    this.setState({selCard:null,deleteModal:false, cards :[]})
     /*d_removeCard(this.state.itemToDel).then(()=>{
         this.cardList();
         this.setState({selCard:null,deleteModal:false})
     }) */
   }

   createAlertDiv(msg){
    alert(msg)
   }
   conektaList = (data)=>{
        
    this.setState({cards:data,cardModal:false,selCard:data[0]})
}


    payment = async () =>{

        var err = [];
        if(this.state.nombre=='' || this.state.nombre.length<3 ){
            err.push('Nombre requerido')
        }
    
        if(this.state.email=='' || this.state.email.length<3 ){
            err.push('Email requerido')
        }
        console.log(err,"errs")
        if(err.length==0){
    
        this.setState({cardWaiting:true});

        //this.createUser({name:this.context.user.nombre + " " + this.context.user.apellidos ,email:this.context.user.username, userId: this.context.user._id}).then( userRes =>{
            
            //console.log(userRes,'rt')
            let data = {
                clientId : this.state.openId,
                source_id : this.state.selCard.id,
                method : 'card',
                amount : this.state.selItem.PRECIOPUBLICO,
                description : this.state.numberto + " - " +  this.state.selItem.NOMBRECOMERCIAL,
                device_session_id :window.deviceSessionId,
                msisdn : this.state.numberto,
                offerings : this.state.selItem.OFFERID,
                nombre : this.state.nombre,
                email : this.state.email
            }
            await d_conekta_payment(data).then(res =>{
                console.log(res,"RES",res.error_code)
                //alert(JSON.stringify(res));
                if(res.http_code==402){
                    this.paymentResponse(res);
                    this.setState({cardWaiting:false});
                }else{
                    this.setState({cardWaiting:false,completeModal:true,successMessage:' ¡Su Recarga ha sido completada exitosamente!', orderId : res.order.id});
                }
                
    
                //this.setState({cards:res})
            }) 
       //});


    }else{
        alert("Verifique sus datos personales")
        //this.setState({message : 'hola',severity:"error",msg:true, waiting :false,waitingOffers:false})
    }
        
    }

    setEmail = (event) => {   this.setState({email : String(event.target.value).trim().toLowerCase()})}
    setNombre = (event) => {   this.setState({nombre : String(event.target.value)})}
    setApellidos = (event) => { this.setState({apellidos : String(event.target.value)})}

   paymentResponse = (res) =>{
    var http_code = res.http_code;
    //ar error_code = res.error_code;
    if(http_code!=undefined){
        var msg = this.createAlertDiv(res.details[0].message);
    }
   }


    render() {
        const { classes, history, session } = this.props;
        
        user = this.context.user;
        const data = this.state.data

      

        return (
            <div>

<ReactWhatsapp style={{position:'fixed',
            cursor:'pointer',
            border:'1px solid #fff',
            bottom:20,right:20,width:'70px',height:'70px',textAlign:'center',backgroundColor:'#25d366',borderRadius:'50%',boxShadow:'0px 4px 10px 0px rgb(0 0 0 / 15%)'}} number="+524428944194" message="Hola me gustaría información adicional." >
                    <svg style={{fill:'#fff',padding:',5rem',width:'50px',height:'50px'}} focusable="false" viewBox="0 0 24 24" width="55" height="55"><path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path></svg>
            </ReactWhatsapp>


                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="card"
                    className={theme.modal}
                    open={this.state.cardModal}
                    onClose={this.handleCardModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.cardModal}>
                    <div className={theme.paper}>
                       <AddCardConekta clientId={this.state.openId} refreshC={this.conektaList} />
                    </div>
                    </Fade>
                </Modal>
               
               

                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="deletecard"
                    className={theme.modal}
                    open={this.state.deleteModal}
                    onClose={this.handleDeleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.deleteModal}>
                    <div className={theme.paper}>
                    <div style={{backgroundColor:'#fff',padding:20}}>
                       ¿Desea remover esta tarjeta de sus métodos de pago? <br/>
                       <ButtonField style={{marginRight:'10px', float:'right'}} onClick={this.handleDeleteModal}  label='Cancelar' /> &nbsp;
                       <ButtonField style={{marginLeft:'10px', float:'right'}} onClick={this.removeCard}  label='Remover' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="deletecard"
                    className={theme.modal}
                    open={this.state.completeModal}
                    onClose={this.handleCompleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.completeModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:20}}>
                       ¡Su Recarga ha sido completada exitosamente!
                       <br/>
                        <h5>ID de orden {this.state.orderId}</h5>
                       <ButtonField onClick={this.completeF}  label='Aceptar' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="addname"
                    className={theme.modal}
                    open={this.state.nameModal}
                    onClose={this.handleNameModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.nameModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:20}}>
                       Por favor agregue su nombre y apellidos para completar su registro
                       <TextField     value={this.state.nombre} label='Nombre'   onChange={this.setNombre}   />
                       <TextField     value={this.state.apellidos} label='Apellidos'   onChange={this.setApellidos}   />
                       <ButtonField onClick={() => {this.updateNames()}}  label='Actualizar' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="oxxmodal"
                    className={theme.modal}
                    open={this.state.oxxoModal}
                    onClose={this.handleOxxoModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.oxxoModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:10}}>
                     
                        
                       <div className={classes.opps}>
                            <div className={classes.oppsHeader}>
                            <div className={classes.oppsReminder}>Ficha digital. No es necesario imprimir.</div>
                            <div className={classes.oppsInfo}>
                                <div style={{width:'100%',float:'left',textAlign:'center',display:'block'}}>
                                <div className={classes.oppsBrand}><img className={classes.oppsBrandImg} src="oxxopay_brand.png" alt="OXXOPay" /></div>
                                <div style={{width:'100%'}}><img style={{marginTop:'20px'}} src={this.state.barcode} /></div>
                              

                               
                                <div style={{width:'100%'}}> <h2 className={classes.oppsAmounth2}>$ {this.state.price}.00 <sup className={classes.oppsAmountsup}>MXN</sup></h2></div>
                                <div style={{width:'100%'}}> <p className={classes.oppsAmountp}>OXXO cobrará una comisión adicional al momento de realizar el pago.</p></div>
                              
                                </div>
                            </div>
                            <div className={classes.oppsReference}>
                                <h3 className={classes.oppsReferenceh3}>Referencia</h3>
                                <h1 className={classes.oppsReferenceh1}>{this.state.referencia}</h1>
                            </div>
                            </div>
                            <div className={classes.oppsInstructions}>
                            <h3>Instrucciones</h3>
                            <ol>
                                <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
                                <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                                <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                            </ol>
                           {/* <div className={classes.oppsFootnote}>Al completar estos pasos recibirás un correo de <strong>FTE Telecomunicaciones</strong> confirmando tu pago.</div>  */}
                            </div>
                        </div>
                      
                       </div>
                    </div>
                    </Fade>
                </Modal>

                 {/*  PAYNET */}
                 <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="paynetmodal"
                    className={theme.modal}
                    open={this.state.paynetModal}
                    onClose={this.handlePaynetModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.paynetModal}>
                    <div className={theme.paper} id={'divpaynet'}>
                        <div style={{backgroundColor:'#fff',padding:10}}>
                     
                        
                       <div className={classes.opps}>
                            <div className={classes.oppsHeader}>
                            <div className={classes.oppsReminder}>Ficha digital. No es necesario imprimir.</div>
                            
                            <div className={classes.oppsInfo}>
                                <div style={{width:'100%',float:'left',textAlign:'center',display:'block'}}>
                                <div className={classes.oppsBrand}><img className={classes.oppsBrandPaynet} src="Openpay_cadenas-afiliadas.png" alt="paynetPay" /></div>
                                <div style={{width:'100%'}}><img style={{marginTop:'20px'}} src={this.state.barcode} /></div>
                              

                               
                                <div style={{width:'100%'}}> <h2 className={classes.oppsAmounth2}>$ {this.state.price}.00 <sup className={classes.oppsAmountsup}>MXN</sup></h2></div>
                                <div style={{width:'100%'}}> <p className={classes.oppsAmountp}>se cobrará una comisión adicional al momento de realizar el pago.</p></div>
                              
                                </div>
                            </div>
                            <div className={classes.oppsReference}>
                                <h3 className={classes.oppsReferenceh3}>Referencia</h3>
                                <h1 className={classes.oppsReferenceh1}>{this.state.referencia}</h1>
                            </div>
                            </div>
                            <div className={classes.guardarBt} onClick={()=> { this.getImage('divpaynet') }}>Guardar imagen.</div>
                            <div className={classes.oppsInstructions}>
                            <h3>Instrucciones</h3>
                            <ol>
                                <li>Acude a la tienda  más cercana.</li>
                                <li>Indica en caja que quieres realizar un pago de <strong>PAYNET</strong>.</li>
                                <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                            </ol>
                           {/* <div className={classes.oppsFootnote}>Al completar estos pasos recibirás un correo de <strong>FTE Telecomunicaciones</strong> confirmando tu pago.</div>  */}
                            </div>
                        </div>
                      
                       </div>
                    </div>
                    </Fade>
                </Modal>


                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}


                {this.state.waiting ? "": 
                    <div style={{textAlign:'center', paddingBottom:'200px'}}>
                    <h1>Recargas</h1>  

                    <h3>Ingrese el número FTE (10 dígitos)</h3>
                    <div style={{float:'left', textAlign:'center',width:'100%'}}>
                    <input style={{fontSize:'18px',fontWeight:'bold',width:'150px',padding:'5px',marginRigth:'10px', textAlign:'center'}} value={this.state.numberto} onChange={this.handleChange.bind(this)} maxlength="10"  type="text" pattern="[0-9]*"  />
                    </div><ButtonField style={{marginTop:'0px !important',float:'left'}} onClick={()=>{this.searchNumber()}}  label='buscar recargas' />
                    <br/>
                    <div style={{marginLeft:'0px',float:'left','marginTop': '21px',width:'100%'}}>
                    
                    </div>
                    

                    {this.state.waitingOffers ? (
                        <div style={{margin:'20px',marginLeft:'40px'}}>
                        <CircularProgress color="inherit" size={18} style={{float:'left', margin:3}} />
                        <div style={{float:'left',fontSize:18}}>Buscando ofertas...</div> 
                        </div>
                    ):null}

                  
               {/* Container 1 */}   
               <Grid direction="row" container style={{marginTop:'20px'}} className={styles.root} spacing={1}>

<Grid item xs='12' md='6'
    justify="space-evenly"
    >


{this.state.selItem && this.state.susinfo==null ? (
   <div style={{width:200, alignItems:'center',justifyContent:'center'}}>
   <ButtonField onClick={this.clearItems}  label='Regresar a todas las recargas' />
   </div>
) : null}       
    
{this.state.susinfo==null &&  this.state.offersF.map( item => (

<Grid item   key={item._id} onClick = { () =>{ this.openpay(item)}}  style={{ justify:'center'}}>
    {(item.selected || this.state.selItem==null) ? (
        <div className={classes.cuadroC}>
            <div className={[classes.cuadroT,item.selected   ?  classes.cuadroSel : null].join(' ')} >
            
            <div style={{ float:'left', marginRight:20}}>
            <div>
            <div className={classes.cuadroPrecio}>${item.PRECIOPUBLICO} MXN</div>
            
            <div className={classes.cuadroSub}> {item.DIAS>0 ? (item.DIAS  + " días / ") : null} IVA incluido</div>

            {/* this.state.selItem==null ? (
                <div className={classes.cuadroBt} style={{cursor:'pointer'}}>SELECCIONAR <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>
            ) : null */ }
            </div >
            </div>

            <div style={{minHeight:20,  width:300, float:'left'}}>
            <div style={{minHeight:20, float:'left', width:'100%', overflowX:'hidden'}}>
            <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:13,  marginRight:5,marginLeft:5}}/> <b>{ parseInt(item.MB) + parseInt(item.MBINT) + parseInt(item.MBTHR)}</b> MB</div>
            <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:13,  marginRight:5,marginLeft:5}} /> <b>{parseInt(item.MINFUP) + parseInt(item.MINVOZ)}</b> minutos de voz</div>
            </div>
            
            <div style={{minHeight:20, float:'left', width:'100%'}}>
                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:13,  marginRight:5,marginLeft:5}} /> <b>{parseInt(item.SMS) + parseInt(item.SMSFUP)}</b> SMS</div>

                {item.TETERING=="SI" ? (
                    <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:13, color:'green', marginRight:5,marginLeft:5}} />Comparte Internet</div>
                ) :null}

                </div>
            </div>
        </div>
        </div>
    ) : null} 
</Grid>

    ))}
    

</Grid>

 {/*  CHECKOUT CARD             
 <Grid item xs='12' md='6'
    direction="row"
    justify="space-evenly"
    
    >      
        <Grid item   className={classes.cuadroB}>
           
        </Grid>   
    </Grid>
    */}



     {/* Cuadro de Pagos */}
{this.state.selItem  ? (             
<Grid item xs='12' md='6'
    justify="space-evenly"
    >

<Grid item   className={classes.cuadroB}>            

<h3>Seleccione su método de pago </h3>




{/* Metodos Pagos */}
<div style={{width:'100%',height: 'auto',overflowY: 'hidden'}}>
<div onClick={e=>{this.setState({metodoPago:1})}} className={[classes.cuadroTipo,this.state.metodoPago==1 ?  classes.cuadroSel :null].join(' ')}>
   <img src='credit-card.svg' style={{width:16, marginRight: 5, }} />  Tarjeta de crédito / debito
</div>

<div onClick={e=>{this.setState({metodoPago:2})}} className={[classes.cuadroTipo,this.state.metodoPago==2 ?  classes.cuadroSel :null].join(' ')}>
<img src='store.svg' style={{width:23, marginRight: 5, }} /> Tiendas de conveniencia
</div>
</div>

<div style={{marginTop:15, float:'left',width:'100%'}}>
<div style={{fontWeight:'bold', fontSize:16, marginBottom:10}}>Datos personales</div>
<TextField     value={this.state.nombre} label='Nombre y Apellidos'   onChange={this.setNombre}   />
<TextField     value={this.state.email} label='Correo electrónico'   onChange={this.setEmail}   />
</div>



{this.state.selItem && this.state.susinfo==null && this.state.metodoPago==1 ? (  
<div>  
<Grid container className={styles.root} spacing={2}>
   
<Grid container 
    direction="row"
    justify="flex-start"
    >
       {this.state.cards.map( item => (
                                  <Grid item   className={classes.cardT}
                                    key={item._id} onClick = { () =>{ this.cardSelect(item)}}  style={{ justify:'center'}}>
                                  <div className={classes.cuadroC} style={{cursor:'pointer'}}>
                                  <div className={[classes.cuadroTarjeta,item.selected ?  classes.cuadroSel :null].join(' ')} >
                                      <div className={classes.removeCard} onClick={()=>{this.removeCardModal(item.id)}}>x</div>
                                         {/* <div className={classes.cuadroTitle}>{item.brand=="visa" ? <img style={{height: 30,paddingTop: 8}} src="/visa.png"/> : <img style={{height: 30,paddingTop: 8}} src="/masterCard.png"/>} </div> */}
                                           <div className={classes.cuadroNombre}> {item.name}</div>  
                                          <div className={classes.cuadroNumeroTarjeta}>{item.number}</div>

                                      </div>
                                  </div>
                    </Grid> 
                    ))}

</Grid>

</Grid>




{this.state.cards.length<3 ? (
        <div style={{marginTop:15, float:'left'}}>
        <ButtonField onClick={this.addCard}  label='Agregar Tarjeta Débito / Crédito' />
        </div>
    )  :null}





{this.state.subscriptionAllowed ? (
        <div style={{fontSize:'22px',float:'left',width:'100%', marginTop:'15px', 'backgroundColor' : 'rgb(230 230 230)',
        'borderRadius': '5px',
        'padding': '12px',
        'paddingLeft': '20px',
        'paddingRight': '20px'}}>
        <FormControlLabel
        control={
        <Checkbox
            checked={this.state.subscriptionMode}
            onChange={this.susCheck}
            name="checkedB"
            color="primary"
        />
        }
        label="Registrar esta tarjeta para pago de renovación automática"
        />
        <div style={{fontSize:'16px',width:'100%',fontWeight:'bold'}}>Al finalizar el periodo de 30 días la bolsa de datos seleccionada se renovará automaticamente.</div>
        </div>
    ) : null}



   {this.state.selCard ? (
        <div >
      <div onClick={()=> {this.payment()}} className={classes.pagarBt} style={{width:'100%', float:'left'}}>
          $ {numberWithCommas(this.state.selItem.PRECIOPUBLICO)} <br />
          Realizar Pago 
    </div>
    <div>


   
    

    

    </div>

      </div>
) :null}





</div>
) : null}

 {/* otros tipos de pago */}
 {this.state.metodoPago==2 ? (
    <div style={{width:'100%',height:'200px', marginTop:10, marginBottom:100, backgroundColor:'0x3333333', float:'left'}}>
    
    <div style={{marginTop:10}}><b>Generar referencia OXXO</b></div>
    <img onClick={this.addOXXO} style={{padding:10,border:'1px solid rgb(208 208 208)',borderRadius:5,cursor:'pointer', backgroundColor:'rgb(255 255 255)'}} className={classes.oppsBrandImg} src="oxxopay_brand.png" alt="OXXOPay" />

    <div style={{marginTop:10}}><b>Generar referencia Paynet</b></div>
    <img onClick={this.addPAYNET} style={{padding:10,border:'1px solid rgb(208 208 208)',borderRadius:5,cursor:'pointer', backgroundColor:'rgb(255 255 255)'}} className={classes.oppsBrandPaynet} src="Openpay_cadenas-afiliadas.png" alt="Paynet" />
    
 </div>
) :null}

</Grid>


</Grid>
) : null}
</Grid>





</div>
}    


</div>  


)  // END RETURN 
}

}


const customStyles = {
    option: (provided, state) => ({
      ...provided,
        fontSize : 16
    }),
   
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }


  const styles = theme => ({
    root: {
        flexGrow: 1,
        padddinTop : 30
      },
      cardT :{
        width:'100%',
        margin: 10
      },
      cuadroC :{
        marginTop : 5,
        marginBottom : 5,
        marginLeft: 0,
        marginRight : 0,
        width : '100%',
        float : 'left'
        
    },
    cuadroTarjeta :{
        height : 'auto',
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 10,
        //minHeight:120,
        width : '100%',
        float : 'left',
        alignItems : 'center',
        alignSelf : 'center',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
        
    },
    modal:{
        "&:focus":{
        outline: 'none'
       }
     },
    cuadroT : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 12,
        paddingBottom : 0,
        marginBottom : 10,
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          },
        height: 'auto',
        overflowY: 'hidden',
        overflowX : 'hidden'

    },
    cuadroB : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 12,
        //paddingBottom : 0,
        marginBottom : 10,
        
        height: 'auto',
        overflowY: 'hidden'
    },
    cuadroTipo : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 12,
        //paddingBottom : 0,
        marginBottom : 10,
        float: 'left',
        height: 'auto',
        overflowY: 'hidden',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          },
        marginRight : 10,
        cursor: 'Pointer'
    },
    guardarBt : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 12,
        //paddingBottom : 0,
        marginBottom : 10,
        float: 'right',
        height: 'auto',
        overflowY: 'hidden',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          },
        marginRight : 10,
        cursor: 'Pointer'
    },
    removeCard :{
        width: 30,
        height: 30,
        fontSize : 16,
        lineHeight: '26px',
        textAlign: 'center',
        borderRadius : 20,
        color : '#fff',
        backgroundColor : '#eb6324',
        cursor : 'pointer',
        float : 'left',
        marginTop : '10px',
        marginRight : '10px'
    },
    cuadroSel : {
        backgroundColor: '#333',
        color : "#fff !important"

    },
    notSel : {
        display : 'none'
    },
    cuadroTitle:{
        fontSize: 16,     
        textAlign: 'center',
        marginBottom : 10,
        textTransform : 'uppercase',
        float : 'left',
        marginTop : '5px'
    },
    cuadroNombre : {
        textAlign : 'left',
        marginLeft : 20,
        float:' left',
        marginTop : 4,
        textTransform : 'uppercase',
    },
    cuadroDesc : {
        fontSize: 14,
        color : '#333',
        textAlign: 'center'
    },
    cuadroPrecio:{
        textAlign : 'left',
        marginLeft : 10,
        color : '#eb6324',
        fontSize : 30,
        fontWeight : 'bold',
       
    },
    cuadroSub:{
        textAlign : 'left',
        marginLeft : 10,
        color : '#eb6324',
        fontSize : 12,
        width: '100%',
        //borderBottom : '1px solid #d8d8d8',
        paddingBottom : 0,
        marginBottom : 10,
        fontWeight : 'bold',
       
    },
    cuadroNumeroTarjeta:{
        textAlign : 'left',
        float: 'left',
        paddingLeft : 20,
        marginLeft : 20,
        color : '#eb6324',
        fontSize : 22,
        fontWeight : 'bold',
        marginTop : 10
       
    },
    cuadroPeriodo:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 13,
        fontWeight : 'bold'
    },
    cuadroCostoSIM:{
        textAlign : 'center',
        fontSize : '14',
        fontWeight : 'bold',
        marginTop : 20,
        marginBottom : 10,
        paddingTop : 20,
        borderTop : '1px solid #ccc'
    },
    cuadroCar:{
        fontSize: 15,
        marginBottom : 10,
        float : 'left',
        marginRight : 10
        //marginLeft : 10
    },
    cuadroBt:{
        marginTop : 10,
        textAlign : 'center',
        padding : 5,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 20,
        fontWeight : 'bold',
        
        verticalAlign:'middle'
    },
    pagarBt : {
        marginTop : 0,
        textAlign : 'center',
        padding : 10,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 0,
        fontSize : 22,
        fontWeight : 'bold',
        cursor : 'pointer',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          },
        verticalAlign:'top'
    },
    title:{
        color : '#eb6324',
        fontSize : 24,
        
        marginTop : 40
    },
    comboTel : {
        fontSize:24,
        borderRadius : 5,
        padding : 10,
        width : 300
    },
    opps : {
        width: '496px',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '0 45px',
        margin: '0px auto',
        overflow: 'hidden',
        border: '1px solid #b0afb5',
        color: '#4f5365'
    },
    oppsReminder : {
        position: 'relative',
        top: '-1px',
        padding: '9px 0 10px',
        fontSize: '11px',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#ffffff',
        background: '#000000'
    },
    oppsInfo : {
        marginTop: '26px',
        position: 'relative'
    },
    oppsInfoAfter :{
         visibility: 'hidden',
         display: 'block',
         fontSize: 0,
         content: " ",
         clear: 'both',
         height: 0
    
    },
    oppsBrand : {
        width: '100%',
        float: 'left'
    },
    oppsBrandImg : {
        maxWidth: '150px',
        marginTop: '2px'
    },
    oppsBrandPaynet : {
        maxWidth: '250px',
        marginTop: '2px'
    }, 
    oppsBrandPaynet : {
        width : '100%',
        marginTop: '2px'
    },  
    oppsAmount  :{
        width: '100%',
        float: 'right',  
    },
    oppsAmounth2: {
        fontSize: '36px',
        color: '#000000',
        lineHeight: '24px',
        marginBottom: '15px'
    },
    oppsAmounth3 :{
        marginBottom: '10px',
        fontSize: '15px',
        fontWeight: '600',
        textTransform: 'uppercase'
    },
    oppsAmountsup : {
        fontSize: '16px',
        position: 'relative',
        top: '-2px'
    },
    oppsAmountp: {
        fontSize: '10px',
        lineHeight: '14px'
    },
  
    oppsReference : {
        marginTop: '14px',
        textAlign : 'center'
        
    },
    oppsReferenceh1 :{

        fontSize: '27px',
        color: '#000000',
        textAlign: 'center',
        marginTop: '-1px',
        padding: '6px 0 7px',
        border: '1px solid #b0afb5',
        borderRadius: '4px',
        background: '#f8f9fa'
    },
    oppsInstructions : {
        margin: '32px -45px 0',
        padding: '32px 45px 45px',
        borderTop: '1px solid #b0afb5',
        background: '#f8f9fa'
    },
    oppsFootnote : {
        marginTop: '22px',
        padding: '22px 20 24px',
        color: '#108f30',
        textAlign: 'center',
        border: '1px solid #108f30',
        borderRadius: '4px',
        background: '#ffffff'
    }
}) 
export default withStyles(styles)(Recargas);