import { createMuiTheme } from "@material-ui/core";

let theme = createMuiTheme({
  
    typography: {
        fontFamily: [
            'Oswald',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
          ].join(','),
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
        h2 :{
            color : '#999'
        }
    },
    palette: {
        text:{
           primary :  '#4e4e4e'
        },
        primary : {
            light: '#63ccff',
            main: '#1a3a63',
            dark: '#006db3'
        },
        secondary : {
            main : '#8C9CA9',
        },
        background: {
            default: '#fff',
        },
        color : { 
            default : '#999'
        },
        success : {
            main : '#008b00',
        },
        info : {
            main : '#0000ff',
        },
        warning : {
            main : '#ff8c00',
        },
        orange: {
            main: '#eb6324'
        }

    },
    shape: {
        borderRadius: 4,
    },      
});

theme = {
    ...theme,
    overrides: {
        MuiFormControl :{
            marginNormal : {
                marginTop : 0,
                marginBottom : 6
            }
        },
        MuiModal : {
            focus : {outline:0}
        },
        MuiPaper :{
            elevation : 0,
            root : {
                backgroundColor : '#f7f7f7'
            },
            elevation1 : {
                boxShadow : '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)'
            }
        },
        MuiInputBase : {
            input :{
                paddingLeft : 5,
            }
        },
        MuiAppbar : {
            root : {
                backgroundColor : theme.palette.primary.main,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#fff',
                width : 300
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
                color : theme.palette.common.white,
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: theme.palette.common.white,
            },
            flexContainer :{
                flexWrap : 'wrap'
            }
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
               
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
        MuiBackdrop : {
            zIndex: 1000,
            color: '#fff',
        },
        MuiContainer : {
            root : {
                padding : theme.spacing(3),
                [theme.breakpoints.down('sm')] : {
                    padding : theme.spacing(2),
                },
                [theme.breakpoints.down('xs')] : {
                    padding : theme.spacing(1),
                },
            },
        },
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        ...theme.mixins,
        toolbar: {
            minHeight: theme.spacing(7),
        },
    },
};

export default theme;