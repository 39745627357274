import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import HttpsRedirect from 'react-https-redirect';


ReactDOM.render(
    <HttpsRedirect>
        <CssBaseline />
        <App />
    </HttpsRedirect>, document.getElementById('root')
    

    
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();