
import React, {useState} from "react";
import { getToken, setToken, getData, delToken } from '../functions.js';
import { readUserView, readOfferInfo, readUserInfo, d_openInfo,d_createUser,d_cardList,d_payment,d_saveCard, d_logout} from '../components/dataController';
import { lang } from '../langAll'



let UserContext = React.createContext();
let { Provider,Consumer} = UserContext;


function UserProvider({children}){

    let [user,setUser] = useState(null)
    let [langR,setLang] = useState(null)
    let [cart,setCart] = useState(localStorage.getItem('cart') || '')

     const login =  async (callback) => {
        let token = getToken()  
        if(token){
            var data = await getData(token, '/users/data')
            
           if(data){
            let langR = lang('es')
            data.lang = langR;
            setUser(data)
            callback(data);
            
           }else{
            //window.location = '/'
           }
        }else{
            //window.location = '/'
        }
    };




    const loginInfo = async (callback) => {
        let token = getToken()  
        if(token){
            var data = await getData(token, '/users/dataUser')
            //console.log(data,"DATATY")
           if(data){
            let langR = lang('es')
            data.lang = langR;

            setUser(data)
            try {
                if(callback) {callback(data);}
            } catch (e){ console.log(e,"ERT")}

            /*return await readUserInfo(data.numbers[0].msisdn).then(dataAltan =>{    
                data.ALTAN =  dataAltan
                //console.log("FG",data)
                setUser(data)
                try {
                    if(callback) {callback(data);}
                } catch (e){ console.log(e,"ERT")}
            });*/
           }else{
           // window.location = '/login'
           }
        }else{
           // window.location = '/login'
        }
    };

    async function  logout(){
        setUser(null)
        delToken()
        window.location = "/"
       // 
    }



   


    return (
        <Provider value={{user, login, loginInfo, logout, langR, cart}}>
            {children}
        </Provider>
    )
}

export  { UserProvider, Consumer as UserConsumer,  UserContext};










/// FUTURE CART
/*

 const cartAdd = async (item,callback)=>{
        var actualCart = JSON.parse(localStorage.getItem('cart'))
        // Search if exists
        let found=0;
        actualCart.map(items =>{
            if(items.id==item.id) found++
        })
        if(found==0){
            actualCart.push(item)
        }
        console.log(item,"itemCart")
        setCart(JSON.stringyfy(actualCart));
    }

    const cartRemove = async (item,callback)=>{
        var actualCart = JSON.parse(localStorage.getItem('cart'))
        // Search if exists
        let found;
        for (let index = 0; index < actualCart.length; index++) {
            const element = actualCart[index];
            if(actualCart[index].id==item.id) {
                found = index
            }
            
        }
        if(found){
            actualCart.splice(found,1)
        }
     
        
        console.log(item,"itemCart")
        setCart(JSON.stringyfy(actualCart));
    }

    */