import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';
import  { UserContext }  from '../contexts/UserContext';

import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, TextField, Modal } from "@material-ui/core";
import ProjectCard from "./projectCard";



import TextLabel from '../components/fields/TextLabel';
import ButtonField from '../components/fields/ButtonField';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Section from '../components/Section';
import { readUserData,updateUserInfo, d_addNumber,d_checkCode, d_removeNumber, d_editNumber } from '../components/dataController';
import Fade from '@material-ui/core/Fade';
import AlertMessage from '../components/fields/AlertMessage';



import theme from '../theme.js';


const styles = theme => ({
        input: {
            textTransform: 'uppercase',
            color : "red",
            width: '100%'
        },
        button : {
            width: '20px'
        }
        
        
})

var user = null;


class Cuenta extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        numeroAdd : null,
        waitingNumero : false,
        validNumber : false,
        numeroCodigo : "",
        error : false,
        open : false,
        message : undefined,
        deleteModal : false,
        editModal : false,
        numberModal : false,
        dataUser : {} , 
            username : "",
            password : "",
            nombre : "",
            apellidos : "",
            razon : "",
            rfc : "",
            direccion : "",
            exterior : "",
            interior : "",
            estado : "",
            cp : "",
            colonia : "",
            numbers : []
    } 

    static contextType = UserContext;


    componentDidMount(){
        

        this.readData()
               
    }

    readData = () =>{
        readUserData().then( res =>{
            console.log(res,"USER")
            this.setState({'waiting':false})
            this.setState({  username : res.username || "",
                             password : res.password || "",
                             nombre : res.nombre || "",
                             apellidos : res.apellidos || "",
                             razon :res.razon || "",
                             rfc : res.rfc || "",
                             direccion : res.direccion || "",
                             exterior : res.exterior || "",
                             interior : res.interior || "",
                             estado : res.estado || "",
                             cp : res.cp || "", 
                             colonia : res.cp || "",
                             numbers : res.numbers
                            });
        })
    }

    getData = (user) =>{
        console.log("getting data")
    }

    setMsg(message,severity='error'){
        //this.setState({message : message,severity:"success",msg:true})
        this.setState({wating:false,error : true,message : message, severity:severity, msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }


    handleNumberModal = () =>{
        this.setState({numberModal:false});
    }

    handleDeleteModal = () =>{
        this.setState({deleteModal:false});
    }
    
    handleEditModal = () =>{
        this.setState({editModal:false});
    }
    

    saveData = async () =>{

        let userInfo = {
            password : this.state.password || "",
            nombre : this.state.nombre || "",
            apellidos : this.state.apellidos || "",
            razon : this.state.razon || "",
            rfc : this.state.rfc || "",
            direccion : this.state.direccion || "",
            exterior : this.state.exterior || "",
            interior : this.state.interior || "",
            estado : this.state.estado || "",
            cp : this.state.cp || "", 
            colonia : this.state.cp || ""

        }
        console.log(userInfo,"user data")

        await updateUserInfo(userInfo).then( res =>{
            console.log(res,"REST")
            this.setState({msg : true,message : res.status, severity:res.severity})
        })
    }

    handleChange = (varName,value,upper) =>{
        console.log(varName,value)
        this.setState({
            [varName]: upper ? String(value).toLocaleUpperCase() : value
        })
    }

    dialogNumber = () =>{
        this.setState({numberModal:true})
    }

    deleteDialog = (msisdn) =>{
        this.setState({deleteModal:true,toDel:msisdn})
    }

    editDialog = (msisdn,alias) =>{
        this.setState({editModal:true,toEdit:msisdn, editAlias:alias, aliasNumero:alias})
    }

    addNumber = async ()=>{
        let msisdn = this.state.numeroAdd;
        this.setState({waitingNumero:true})
        await d_addNumber({msisdn}).then((res)=>{
           if(res.errorCode){
                this.setMsg(res.description)
                this.setState({waitingNumero:false})
           }else{
            this.setState({waitingNumero:false,validNumber:true})
           }
           
        })
    }

    saveAlias = async ()=>{
        let msisdn = this.state.toEdit;
        let alias = this.state.aliasNumero
        this.setState({waitingEdit:true})
        await d_editNumber({msisdn:msisdn,alias:alias}).then((res)=>{
                
                this.setState({editModal:false});
                this.setMsg("Se ha actualizado el alias del número",'success');
                this.readData();
        })
    }

    checkCode = async () =>{
        await d_checkCode({code:this.state.numeroCodigo,msisdn:this.state.numeroAdd}).then( (res)=>{
            if(res.errorCode){
                this.setMsg(res.description)
            }else{
                this.setMsg("Número agregado a la cuenta",'success')
                this.setState({numberModal:false});
                this.readData();
            }
        })
    }

    removeNumber = async (number) =>{
        await d_removeNumber({msisdn:this.state.toDel}).then( (res)=>{
            this.setMsg("Se ha removido el número",'success');
            this.readData();   
            this.setState({deleteModal:false});
        })  
    }


    render() {
        const { classes,  history, session } = this.props;
        user = this.context.user;
        const data = this.state.data
        
 
        return (
            <div>

                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

                {/* MODAL AGREGAR MENSAJE  */}
                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="addnumber"
                    aria-describedby="number"
                    className={theme.modal}
                    open={this.state.numberModal}
                    onClose={this.handleNumberModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.numberModal}>
                    <div className={theme.paper}>
                        <Section>
                        <Grid container spacing={4}>
                        <Grid item md={12}    >
                            <p>Se enviará un mensaje SMS con un código para validar.</p>
                            {this.state.error ? <AlertMessage open={this.state.error} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> : null}
                           {!this.state.waitingNumero ? (
                            <div>


                            <TextField style={{width:'100%',fontSize:18,textAlign:'center'}} value={this.state.numeroAdd} label='Agregar número (10 dígitos)' onChange={event => this.setState({ numeroAdd: event.target.value })} />
                                {!this.state.validNumber ? (
                                     <ButtonField onClick={this.addNumber} label='Agregar número' />
                                ) :null}
                              
                            </div>
                           )  :(
                               <div style={{alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                                 <CircularProgress style={{alignItems:'center',justifyContent:'center',textAlign:'center'}} color="inherit" />
                               </div>
                           )}

                           {this.state.validNumber ? (
                               <div>
                                <TextField style={{width:'100%'}} value={this.state.numeroCodigo} label='Digite su código recibido' onChange={event => this.setState({ numeroCodigo: event.target.value })} />
                                <ButtonField onClick={this.checkCode} label='Validar código' />
                               </div>
                           ) :null}
                            
                        </Grid>
                      
                        </Grid>
                        </Section>
                    </div>
                    </Fade>
                </Modal>

                {/* MODAL EDITAR ALIAS */}
                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="editnumber"
                    aria-describedby="edit"
                    className={theme.modal}
                    open={this.state.editModal}
                    onClose={this.handleEditModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{timeout: 500 }} >
                    <Fade in={this.state.editModal}>
                    <div className={theme.paper}>
                        <Section>
                        <Grid container spacing={4}>
                        <Grid item md={12}    >
                            <p>Editar Alias de número. {this.state.toEdit}</p>
                            <div>
                            <TextField style={{width:'100%'}} value={this.state.aliasNumero} label='Alias de número' onChange={event => this.setState({ aliasNumero: event.target.value })} />
                            <ButtonField onClick={this.saveAlias} label='Guardar' />
                            </div> 
                        </Grid>
                      
                        </Grid>
                        </Section>
                    </div>
                    </Fade>
                </Modal>



                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="confirmdel"
                    aria-describedby="quitar número"
                    className={theme.modal}
                    open={this.state.deleteModal}
                    onClose={this.handleDeleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}>
                    <Fade in={this.state.deleteModal}>
                    <div className={theme.paper}>
                        <Section>
                        <Grid container spacing={4}>
                        <Grid item md={12}    >
                            <p>¿Desea quitar este número de su cuenta?.</p>
                            {this.state.error ? <AlertMessage open={this.state.error} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> : null}
                            <ButtonField onClick={() => {this.setState({deleteModal:false})}} label='Regresar' />
                            <ButtonField onClick={()=> {this.removeNumber()}} label='Quitar número ' />
                        </Grid>
                        </Grid>
                        </Section>
                    </div>
                    </Fade>
                </Modal>


                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

                {this.state.waiting ? "": 
                    <div>


                    <h1>Datos de la cuenta</h1>  

                <Section title='Datos generales' >
                    <Grid container spacing={4}>
                        <Grid item md={3} sm={6} xs={12}   >
                            <TextField disabled={true} value={this.state.username} label='Usuario'  />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField  value={this.state.password} type="password" label='Password' onChange={ e => {this.handleChange('password',e.target.value)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField  classes={{root:classes.root}} inputProps={{autoCapitalize: 'on'}} value={this.state.nombre} label='Nombre' onChange={ e => {this.handleChange('nombre',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField classes={{root:classes.root}} value={this.state.apellidos} label='Apellidos' onChange={ e => {this.handleChange('apellidos',e.target.value,true)}} />
                        </Grid>
                    </Grid>  
                </Section>

                <Section title='Datos Fiscales' >
                <Grid container spacing={4}>
                        <Grid item  sm={6} xs={12}   >
                            <TextField className={classes.input} value={this.state.razon} label='Razón Social' onChange={ e => {this.handleChange('razon',e.target.value,true)}} />
                        </Grid>
                        <Grid item  sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.rfc} label='RFC' onChange={ e => {this.handleChange('rfc',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.direccion} label='Dirección' onChange={ e => {this.handleChange('direccion',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField  className={classes.input} value={this.state.exterior} label='Número exterior' onChange={ e => {this.handleChange('exterior',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.interior} label='Número interior' onChange={ e => {this.handleChange('interior',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.estado} label='Estado' onChange={ e => {this.handleChange('estado',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.cp} label='Código Postal' onChange={ e => {this.handleChange('cp',e.target.value,true)}} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <TextField className={classes.input} value={this.state.colonia} label='Colonia' onChange={ e => {this.handleChange('colonia',e.target.value,true)}} />
                        </Grid>

                    </Grid> 

                    <div style={{width:200, margin:30,alignSelf:'center', textAlign:'center'}}></div>
                    <ButtonField  onClick={this.saveData} label='Guardar cambios' />


                </Section>

                

                <Section title='Número asociados' >
                <div style={{width : '180px'}}>
                    <ButtonField onClick={this.dialogNumber}  label='Asociar número nuevo' />
                </div>
                
                   {this.state.numbers.map( (item) => {
                       if(item.status==0){
                         return (  
                        <div key={item.msisdn} style={{display:'flex'}}>
                        <div style={{width:'10px', marginRight:10}}><ButtonField style={styles.button} onClick={() =>{this.deleteDialog(item.msisdn)}}  label='x' /></div>
                        <h3 style={{marginLeft:60, marginRight:30,cursor:'pointer'}}   key={item.msisdn}>{item.msisdn} 
                        
                        </h3>  <ButtonField style={{marginLeft:20}} onClick={() =>{this.editDialog(item.msisdn, item.alias)}}  label={item.alias || "Alias"} />
                        </div>)
                       }
                         
                 })}   

                     
                </Section>

               
                

                    </div>
                }     

          
            </div>

            
        )
    }

}


export default withStyles(styles)(Cuenta);