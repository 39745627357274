import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo, getAvisos } from '../components/dataController';

import { Grid, Backdrop, CircularProgress, Button, Modal,  Container } from "@material-ui/core";
import theme from '../theme.js';
import Section from '../components/Section';

import Telefonia from '../contexts/Webpages/Telefonia';
import Telefoniafija from '../contexts/Webpages/Telefoniafija';
import Internet from '../contexts/Webpages/Internet';
import Cobertura from '../contexts/Webpages/Cobertura';
import Contacto from '../contexts/Webpages/Contacto';
import Recargasp from '../contexts/Webpages/RecargasP';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';

import WebLayout from '../components/WebLayout';
import HomeContent from '../components/HomeContent'


import tawkTo from "tawkto-react";

import AfiliaForm from '../pages/AfiliaForm'

const tawkToPropertyId = '602aaa83918aa261273f1803'
const tawkToKey = '1eujti341'


const title = 'Inicio'

const styles = theme => ({
    backgroundColor : '#000',
    float :{
        position:'fixed',
        width:60,
        height:60,
        bottom:40,
        right:40,
        backgroundColor:'#25d366',
        color:'#FFF',
        borderRadius:50,
        textAlign:'center',
        fontSize:30,
        boxShadow: '2px 2px 3px #999',
        zIndex:100,
    },
    myFloat : {
        marginTop:16,
        width :30,
        height : 30
    }
})


class Home extends React.Component {

    state ={
        afiliaForm : false,
        msg : false,
        message : 'FTE ',
        severity : 'error'
    } 

    static contextType = UserContext;
    
    componentDidMount(){
        this.context.login(()=>{
            console.log("completed login")
        }); 
        //tawkTo(tawkToPropertyId, tawkToKey)
        this.getMessages();
    }

    gologin = () =>{
        this.props.history.push('/login')
    }

    gocrear = () =>{
        this.props.history.push('/cuentac')
    }

    openAfilia =  () =>{
        this.setState({afiliaForm:true});
    }

    closeAfilia =  () =>{
        this.setState({afiliaForm:false});
    }

    handleCloseModalAfilia = () =>{
        this.setState({afiliaForm:false});
    }

    getMessages = () =>{
        getAvisos().then(res =>{
            if(res.length>0){
                this.setState({msg:true,severity:res[0].tipo,message:res[0].mensaje})
            }
            
        })
    }

    

    render(){

        const { classes, history, session } = this.props;
        const data = this.state.data
        const { hash } = this.props.location


        return(
            
            <div>

            <Container style={{paddingBottom:0,paddingRight:20, paddingLeft:20}} maxWidth="lg" >
         
         {/* <AlertMessage open={this.state.msg} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> */}

            {this.state.msg ? (
                  <Alert variant="filled" severity={this.state.severity} onClose={this.state.onClose} >
                  {this.state.message}
              </Alert>    
      
            ) :null}
          


            </Container>
            
            <WebLayout  tab={hash != '' ? hash : '#inicio'} >
                
                
               {/* <a href="https://api.whatsapp.com/send?phone=5215568269633&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n" style={{ position:'fixed',
                        width:60,
                        height:60,
                        bottom:40,
                        right:40,
                        zIndex : 10000,
                        color:'#FFF',
                        borderRadius:50,
                        textAlign:'center',
                        position : "fixed"
                                                }} target="_blank">
                    <img src="whats.png" style={{width:60,height:60}} /> 
                </a>

                                            */}

                <Modal  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                    aria-labelledby="afiliar"
                    aria-describedby="afiliacion"
                    className={theme.modal}
                    open={this.state.afiliaForm}
                    onClose={this.handleCloseModalAfilia}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    
                    }}
                >
                    <Fade in={this.state.afiliaForm}>
                    <div className={theme.paper}>
                       <AfiliaForm  closeAfilia ={this.closeAfilia}/>
                    </div>
                    </Fade>
                </Modal>






            { hash == '#inicio' ?
                <div id='#inicio'>
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <HomeContent afif={this.openAfilia} closeAfilia={this.closeAfilia}/>
                            </Grid> 
                        </Grid>
                    </div>
                    
                </div>
            : null }
            { hash == '#telefonia' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Telefonia afif={this.openAfilia} closeAfilia={this.closeAfilia} />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#telefoniafija' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Telefoniafija afif={this.openAfilia} closeAfilia={this.closeAfilia} />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
             { hash == '#internet' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Internet />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
           
            { hash == '#cobertura' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Cobertura/>
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#contacto' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Contacto />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#recargasp'  || hash == '' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Recargasp />
                            </Grid> 
                        </Grid>
                    </div>
            : null }

            
        </WebLayout>
        </div>
        )
         
     
    }
}



export default withStyles(styles)(Home);