import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class Terminos extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
                
                

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>Términos y Condiciones de las Ofertas Comerciales de RED FTE</div>
                </Grid>

                <div >
    
          <div >

<p> Los presentes términos y Condiciones de las Ofertas Comerciales aplican para todos nuestros Suscriptores que contraten los servicios Telefonía Móvil en modalidad de Prepago de FTE a través de recargas de paquetes FTE – 100, FTE – 200, FTE – 300 y FTE – 500.</p>

<p>Los servicios ofrecidos al Suscriptor requieren que cuente con un Equipo de Telefonía Móvil que sea compatible con la Banda 28 y se encuentre debidamente homologado. La compatibilidad podrá ser verificada en el siguiente link: <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></p>

<br/><br/>
<ul>
    <li><b>FTE-100</b></li>
    <li>5 GB</li>
    <li>1,500 minutos</li>
    <li>500 SMS</li>
</ul>

<b>Costo $100 MXN.</b>

<b><p>La oferta incluye</p></b>

<ul>La oferta incluye:
    <li>5,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá</li>
    <li>250 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>


<br/><br/>
<ul>
    <li><b>FTE-200</b></li>
    <li>40 GB</li>
    <li>1,500 minutos</li>
    <li>1,000 SMS</li>
</ul>

<b>Costo $200 MXN.</b>

<b><p>La oferta incluye</p></b>

<ul>La oferta incluye:
    <li>20,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>20,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>500 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>No cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>


<br/><br/>
<ul>
    <li><b>FTE-300</b></li>
    <li>40 GB</li>
    <li>1,500 minutos</li>
    <li>1,000 SMS</li>
</ul>

<b>Costo $300 MXN.</b>

<b><p>La oferta incluye</p></b>

<ul>La oferta incluye:
    <li>20,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>20,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>500 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>

<br/><br/>
<ul>
    <li><b>FTE-500</b></li>
    <li>100 GB</li>
    <li>10,000 minutos</li>
    <li>10,000 SMS</li>
</ul>

<b>Costo $500 MXN.</b>

<b><p>La oferta incluye</p></b>

<ul>La oferta incluye:
    <li>50,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>50,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>5,000 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>5,000 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>


          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Terminos);