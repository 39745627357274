import { layout_config } from '../config.js'

import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Container, CssBaseline, Box, Grid } from "@material-ui/core";
import { ChevronLeft, MenuRounded, ChevronRight } from "@material-ui/icons";
import { Root, Header, Nav, Content, Footer } from "mui-layout";
import { presets } from 'mui-layout';
import  { UserContext }  from '../contexts/UserContext';

import AppHeader from './AppHeader';
import TabsHeader from './TabsHeader';
import AppNavigator from './AppNavigator';

import theme from '../theme.js';

import tawkTo from "tawkto-react";


const tawkToPropertyId = '602aaa83918aa261273f1803'
const tawkToKey = '1eujti341'



class AppLayout extends React.Component {

    static contextType = UserContext;


    componentWillMount() {
      //tawkTo(tawkToPropertyId, tawkToKey)
    }

    logout =() =>{
      this.context.logout()
    }

    render(){
        const { header, drawer, title, tabs, tab } = this.props;

        let config = {
            ...layout_config,
            navWidth : drawer != false ? 0 : 0,
            //navWidth : drawer != false ? 256 : 0,
        }

        return (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Root config={config} >

                
                { header != false ?
                  <Header style={{ backgroundColor : '#fff' }} renderMenuIcon={open => (open ? <ChevronLeft /> : <MenuRounded style={{ color : theme.palette.common.white }} />)} >
                    <Container>
                    <AppHeader title={title} />
                    </Container>
                  </Header>
                : null}

                    
              
                 { drawer != false ?
                <Nav renderIcon={collapsed => collapsed ? <ChevronRight /> : <ChevronLeft /> } >
                  <Container >
                      <AppNavigator /> 
                   </Container>
                </Nav>
                : null}    
                

                   <TabsHeader tabs={tabs} tab={tab} logout={this.props.logout} />
          


                
                   
                    <Container>
                        { this.props.children }
                    </Container>
        
                   
                    
              </Root>
            </ThemeProvider>
        )
    }
}

export default AppLayout;