import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    TextField,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    DialogContentText,
    FormControl,
    MenuItem,
    InputLabel,
} from '@material-ui/core/';

import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
    select : {
        marginBottom : theme.spacing(1),
        marginTop : theme.spacing(1),
        paddingBottom : theme.spacing(0),
        paddingTop : theme.spacing(0),
    },
})

class AutoCompleteField extends Component {

    state = {
        value : {
            name : undefined,
            _id : undefined,
        }
    }

    getSelectedItem(){
        const { data, value } = this.props

        const item = data.find((option)=>{
            if (option._id == value)
                return option;
        })
        return item || {};
    }

    handleChange = (event, value) => {
        const { onChange, field } = this.props

        if(value){
            onChange(value)
        }
    }

    componentWillMount() {

    }

    render(){
        const { classes, data, value, onChange, label, autoFocus, field } = this.props;

        return (
            <Autocomplete
                id="combo-box-demo"
                options={data}
                getOptionLabel={option => option[field]}
                onChange={this.handleChange}
                className={classes.select}
                selectOnFocus={true}
                //getOptionSelected={(option, value) => option._id == value} 
                value={value}
                //inputValue={this.state.input}
                //onInputChange={this.setSearch}
                renderInput={params => (
                    <TextField {...params} variant="outlined" label={label} fullWidth />
                )}
            />
        )
    }
}

export default withStyles(styles)(AutoCompleteField);
