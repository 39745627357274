import React from "react";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";

import Home      from "./pages/home";
import Login     from "./pages/login";
import Node      from "./pages/node";
import ProjectView   from "./pages/projectview";
import Netview   from "./pages/netview";
import Signup    from "./pages/signup";
import Dashboard   from "./pages/dashboard";
import Aviso from './contexts/Webpages/Aviso';
import Terminos from './contexts/Webpages/Terminos';

import Carta from './contexts/Webpages/Carta';
import Colaboracion from './contexts/Webpages/Colaboracion';
import UsoJusto from './contexts/Webpages/UsoJusto';

import { UserProvider }  from "./contexts/UserContext";

const  App = ()  => {
        return (
            <UserProvider>
                <Router>
                  <Switch>
                    <Route path="/login"        component={Login} />
                    <Route exact path="/"       component={Home} />
                    <Route exact path="/aviso"       component={Aviso} />
                    <Route exact path="/terminos"       component={Terminos} />
                    <Route exact path="/carta"       component={Carta} />
                    <Route exact path="/colaboracion"       component={Colaboracion} />
                    <Route exact path="/usojusto"       component={UsoJusto} />
                    <Route exact path="/dash"      component={Dashboard} />
                    <Route path="/cuentac"       component={Signup} />
                  
                  </Switch>
                </Router>
            </UserProvider>
        )
}

export default App;