import { url_server } from '../config.js'

import { setToken, delToken } from '../functions.js'
import { signup } from '../components/dataController';
import React, { Component } from "react";
import {
    Container,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
} from '@material-ui/core/';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress} from '@material-ui/core';

import AppLayout from '../components/AppLayout';
import TextField from '../components/fields/TextField';
import ButtonField from '../components/fields/ButtonField';
import AlertMessage from '../components/fields/AlertMessage';
import CheckBoxField from '../components/fields/CheckBoxField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const styles = theme => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginNormal : {
            marginTop: 0,
            marginBottom: 0
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
 
})

class Signup extends Component {

    state = {
        username : '',
        password : '',
        error : false,
        message : undefined,
        withSIM : true,
        imei : undefined,
        sim : '',
        waiting : false,
        open : false,
        nombre : '',
        apellidos : ''

    }

    setUsername = (event) => { this.setState({username : String(event.target.value).trim()}) }
    setPassword = (event) => { this.setState({password : String(event.target.value).trim()})}
    setNombre = (event) => {   this.setState({nombre : String(event.target.value).trim()})}
    setApellidos = (event) => { this.setState({apellidos : String(event.target.value)})}
    setSimN     = (event) => { this.setState({sim      : String(event.target.value).trim()})}

    closeError = (event) => {
        this.setState({error : false})
    }


    setSIM = (event, value) => {
        this.setState({withSIM : value})
    }
   

     signupF = async (e) => {
        // 5628230800 HECTOR
        // 4613473137 CELAYA
        // PORTADO 5510021292
        // 8952140061756026008 5628282323 PATRON
        // 8952140061756034895 4613471005 MIO NUEVO
        //   RECARGA

        e.preventDefault();


        var errs=[];

        if(this.state.username.length<5){
            errs.push('Usuario invalido')
        }

        if(this.state.password.length<5){
            errs.push('Contraseña requerida 6 carácteres mínimo')
        }

        if(this.state.sim.length<19){
            errs.push('No. de SIM inválido')
        }

        if(this.state.nombre.length<5){
            errs.push('Nombre requerido')
        }

        if(this.state.apellidos.length<5){
            errs.push('Apellidos requeridos')
        }

       

        console.log(errs,"errs-----------")

        if(errs.length==0){
            this.setState({waiting:true}, async ()=>{
                let signRes = await signup(this.state.username,this.state.password,this.state.sim, this.state.nombre,this.state.apellidos);
                console.log(signRes,"RES SIGN")
                if(signRes.error_code){
                    this.setState({waiting:false,msg:[signRes.status],open:true})
                }else{
                    this.setState({waiting:false,msg:[signRes.status],open:true});
                    window.location = '/dash'

                }
                
            });
        }else
        {
            this.setState({waiting:false,msg:errs,open:true})
        }
        
        
       
    }



    handleClose = () =>{
        this.setState({open:false})
    }

    render(){
        const { classes } = this.props;

        return (
            <AppLayout header={false} drawer={false} logout={false}>

            <Grid container spacing={4} direction="column" alignItems="flex-start" justify="flex-start" style={{ }} >
                <Grid item xs>
                    <img src="logo.png" />
                </Grid>
            </Grid>

              <Grid container spacing={4} direction="row" alignItems="flex-start" justify="center" style={{ minHeight: '75vh' }} >
              
              <Grid item xs={12}  md={6} > 
                <img style={{width:'100%' , height:'100%', margin:0, borderRadius:10}} src="telefonia.jpg" />
              </Grid>

              <Grid item xs={12}  md={6} >
                <Card className={classes.card}>
                  <CardContent>
                    <h1>Crear usuario nuevo</h1>
                    <form className={classes.form} onSubmit={this.signupF} noValidate>
                      <TextField     value={this.state.username} label='Correo / (este será tu usuario)'                 onChange={this.setUsername} />
                      <TextField     value={this.state.password} label='Contraseña'             onChange={this.setPassword} type='password'  />
                      <TextField     value={this.state.nombre} label='Nombre'   onChange={this.setNombre}   />
                      <TextField     value={this.state.apellidos} label='Apellidos'   onChange={this.setApellidos}   />
                {/*   <TextField     value={this.state.telefono} label='Teléfono de contacto'   onChange={this.setTel}   />   */}
                      <TextField     value={this.state.sim} label='No.de tarjeta SIM '             onChange={this.setSimN}   />

                    <div style={{marginTop:18}} >
                   {/* <CheckBoxField value={this.state.withSIM} label='¿Ya cuentas con un SIM? Activalo con tu cuenta.' onChange={this.setSIM} />   */}
                    </div>

                    {this.state.withSIM ?
                     (
                        <div>
                       {/* <TextField     value={this.state.imei} label='IMEI (15 Dígitos)  Marque *#06# en su teléfono para obtenerlo'                   onChange={this.setIMEM}  /> */}
                        
                        </div>
                     ): null}
                      
                    {this.state.open ? (
                        <div >
                        <MuiAlert onClose={this.handleClose} severity="warning">
                            
                            {this.state.msg.map(item=>{
                                return <div>
                                 <p>{item}</p> 
                               </div>
                            })}
                            
                        </MuiAlert>
                    </div>  
                    ):null}
                      


                    {!this.state.waiting ? 
                          <ButtonField   label='Crear usuario' onSubmit={this.signupF} />
                    :
                        <CircularProgress style={{alignItems:"center",alignSelf:"center", alignContent:'center'}} size={20} />
                    }
                    
                      <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
                </Grid>
              </Grid>
              {this.state.error ? <AlertMessage open={this.state.error} message={this.state.message} onClose={this.closeError} /> : null}
            </AppLayout>
        );
    }
}

export default withStyles(styles)(Signup);