import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { numberWithCommas } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo, readOfferInfo, readUserView } from './dataController';
import Section from '../components/Section';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import ProjectCard from "./projectCard";
import AlertMessage from './fields/AlertMessage';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../theme.js';

const styles = theme => ({

})
var user = null;

class Telinfo extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        dataUser : {},
        freeUnits : [],
        selMSISDN : 0,
        numbers : [],
        actualN : [],
        actualInt : 0,
        numbersRes : [],
        numberOn : ""
    } 

    static contextType = UserContext;

    async componentDidMount(){
        this.setState({'waiting':true})

        this.context.login( (res) => {
            console.log(res,"login response",this.context.user)
            this.setState({numbers:res.numbers})

           this.initDown()
        });      
    }

    async initDown(){

        let numberInfo = this.state.numbers[this.state.actualInt]
        
         await readUserInfo(numberInfo.msisdn).then(dataAltan =>{
            dataAltan.info =  numberInfo;


       

            let procedData = ""
            if(!dataAltan.errorCode){
                procedData = this.proc(dataAltan);
            


            }else{
                console.log('regreso proc2')
                procedData = this.proc({data:{msisdnStatus:'Erroneo'}});
            }

            let numberOn = ""
            if(this.state.numbers[this.state.actualInt+1]){
                numberOn = this.state.numbers[this.state.actualInt+1].msisdn
            }
            
            console.log()
            this.setState({actualInt:this.state.actualInt+1,waiting:true,numberOn:numberOn})
            
            if(!dataAltan.errorCode){
                console.log('setting datas ', procedData)
                this.setState(prevState => ({
                    freeUnits: [...prevState.freeUnits, procedData],
                  }))
            }
            
            
            
              

            if(this.state.actualInt<this.state.numbers.length){
                this.initDown()
            }else{
                console.log("termino numeros",this.state.numbersRes)
                //this.setState({backDOn:true})
                //this.proc()
                this.setState({waiting : false})
            }
            
        });
    }

    proc(dataAltan){

            var data = dataAltan
            var freeUnits = []

            var bolsasData = ['FU_ThrMBB_Altan-RN_1Mbps_CT',
                             'FU_ThrMBB_Altan-RN_512kbps_CT',
                              'FU_Data_Altan-RN_CT',
                              'FU_Data_Altan-NR-IR_NA_CT',
                              'FU_Data_Altan-NR-IR_NA',
                              'FreeData_Altan-RN',
                              "FU_ThrMBB_Altan-RN_512kbps"];
            var bolsasMIN = ['FU_Min_Altan-NR-IR-LDI_NA','FU_Min_Altan-NR-LDI_NA'];
            var bolsasSMS = ['FU_SMS_Altan-NR-IR-LDI_NA','FU_SMS_Altan-NR-LDI_NA'];
            
        

            var units = dataAltan.responseSubscriber.freeUnits
            var status = data.responseSubscriber.status.subStatus


            console.log(units,status,'data')

            if(status=="Active"){
                     // FREE MB
                var total_MB = 0;
                var unused_MB = 0;
                for (let index = 0; index < units.length; index++) {

                    console.log('pasando',units[index].name)
                        if(bolsasData.indexOf(units[index].name)!=-1){
                            total_MB += parseInt(units[index].freeUnit.totalAmt);
                            unused_MB += parseInt(units[index].freeUnit.unusedAmt);
                        } 
                }


                let total  = parseFloat(total_MB).toFixed(0)
                let used   = parseFloat(total_MB-unused_MB).toFixed(0)
                let free  = unused_MB.toFixed(0)
                let perc   =   ((used / total) ) || 0
               
                var obj = {
                    name:"freeUnits",
                    desc:"MB",
                    perc:perc,
                    total:total,
                    used : used,
                    free:free
                }

                console.log(obj,"MB")
                freeUnits.push(obj)
    
                // MINUTES

                var total_MIN = 0;
                var unused_MIN = 0;
                for (let index = 0; index < units.length; index++) {
                        if(bolsasMIN.indexOf(units[index].name)!=-1){
                            total_MIN += parseInt(units[index].freeUnit.totalAmt);
                            unused_MIN += parseInt(units[index].freeUnit.unusedAmt);
                        } 
                }

              
                total  = parseFloat(total_MIN|| 0).toFixed(0) 
                 used   = parseFloat(total_MIN-unused_MIN || 0).toFixed(0) 
                 free = parseFloat(unused_MIN  || 0)
                 perc   =   ((used / total) ) || 0 
            
                var obj = {
                    name:"minutes",
                    desc:"MINUTOS",
                    perc:perc,
                    total:total,
                    used : used,
                    free:free
                }
                console.log(obj,"MB")
                freeUnits.push(obj)
    
                // SMS

                var total_SMS = 0;
                var unused_SMS = 0;
                for (let index = 0; index < units.length; index++) {
                        if(bolsasSMS.indexOf(units[index].name)!=-1){
                            total_SMS += parseInt(units[index].freeUnit.totalAmt);
                            unused_SMS += parseInt(units[index].freeUnit.unusedAmt);
                        } 
                }

                 total  = parseFloat(total_SMS || 0).toFixed(0) 
                 used   = parseFloat(total_SMS-unused_SMS || 0).toFixed(0) 
                 free =  parseFloat(unused_SMS || 0) || 0
                 perc   =   ((used / total) ) || 0 
              
                var obj = {
                    name:"sms",
                    desc:"SMS",
                    perc:perc,
                    total:total,
                    used : used,
                    free:free
                }
                console.log(obj,"MB")
                freeUnits.push(obj)

               data.calc = freeUnits;
            }else{
                
            }
            
           
            return data;

            //this.procOffers(data.responseSubscriber.freeUnits);
           // this.getOfferInfo(data.responseSubscriber.primaryOffering.offeringId)
           // this.setState({data:data,waiting:false, freeUnits : numbersArr})

     
        
    }

    procOffers(offers){
        var offArr = [];
        for (var i=0; i<offers.length;i++){
            offArr.push({offerid:offers[i].detailOfferings[0].offeringId, name :offers[i].name })
        }
        console.log(offArr)
    }


    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };
    

    


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }


    filterTipo(item){

      

        let exclude = "FU_Data_Altan-RN_RG18";
        var dated = 0
        for (let index = 0; index < item.length; index++){
            var datedon = item[index].detailOfferings[0].expireDate;

            if(datedon>dated && item[index].name!=exclude){
               dated = datedon; 
            }
            
        }


        var filter = [];
        if(item){
            for (let index = 0; index < item.length; index++) {
                if(item[index].offerInfo && item[index].offerInfo.ACCION=="ACTIVACION"){
    
                    console.log(item[index],"item on")    
                    let fechaFormat = dated || "" //item[index].detailOfferings[0].expireDate || "";
                    let year = fechaFormat.substring(0,4);
                    let month = fechaFormat.substring(4,6);
                    let day = fechaFormat.substring(6,8)
                    let fechaF = day + "/" + month + "/" + year 
                    item[index].expireDate = fechaF
    
                    filter.push(item[index])
                    break;
                }
                
            }  
    
            return (filter.map(inter =>(
                <div key={inter.msisdn}>
                <div style={{float:'left'}} >  {inter.offerInfo.NOMBRECOMERCIAL}  / <b>Expira</b> {inter.expireDate} </div>
               
                 </div>
            ))
    
            )
        } else{
            return [];
        }
        
        
    }

    render() {
        const {  history, session } = this.props;

        const user = this.context.user;
       
        const buildStylesV = buildStyles({
            rotation: .75,
            strokeWidth: 3,
            textSize: '10px',
            pathTransitionDuration: 1,
            pathColor: '#ec6223',
            textColor: '#000',
            trailColor: '#1a3a63',
            backgroundColor : '#fff',
            backgroundColor: '#fff',
            transition: 'stroke-dashoffset 0.5s ease 0s',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
            strokeLinecap: "butt"
          })

        
        const data = this.state.data
       
 
        return (
            <div>

               


                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

               
                     <div>

                     {this.state.freeUnits.length==0 && !this.state.waiting ? (
                         <h2>No se encontró información del número</h2>
                     ): null}    
                        
                        {this.state.freeUnits && this.state.freeUnits.map((numb) => (
                           <Section key={numb.info.msisdn}>
                             
                            <div style={{width:'100%',textAlign:'left',float:'left',fontSize:'28px',fontWeight:'bold'}}>{numb.info.msisdn} &nbsp; <div style={{fontSize:'28px',float:'right',marginLeft:"20px"}}>{numb.info.alias}</div>
                               
                            </div>
                            {this.filterTipo(numb.responseSubscriber.freeUnits)}
                            
                            
                            {numb.responseSubscriber.status.subStatus!="Active" ? (
                                <h2>{numb.descriptionStatus}</h2>  
                            ): (
                            
                    <div>

                {/*  FECHA EXPIRACION Y PLAN  */}
                


                               <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{paddingTop : 30,marginBottom:30}}
                            >
                            
                           

                            {numb.calc.map( itemd => (
                                <Grid key={itemd.id} item xs={12} sm={4} style={{justify:'center',alignSelf:'center'}}>
                                <div key={itemd.name} style={{textAlign:'center',justify:'center', width:140, margin:'0 auto'}} >
                                <CircularProgressbar circleRatio={0.5} value={itemd.perc} maxValue={1}  strokeWidth={25}  styles={buildStylesV} />
                                
                                <div  style={{marginTop:-60}}>
                                <div style={{marginTop:15, fontSize:22, fontWeight:'bold'}}>{itemd.desc}</div>
                                <div style={{justify:'center', marginBottom:40,marginTop:-16}}>
                                    <div style={{marginTop:20}}>Usados: <b>{numberWithCommas(itemd.used)}</b></div>
                                    <div style={{marginTop:0}}>Libres: <b>{numberWithCommas(itemd.free)}</b> </div>
                                    <div style={{marginTop:0}}>Totales: <b>{numberWithCommas(itemd.total)}</b> </div>
                                    {/* <p style={{marginTop:0}}>Totales: {item.total}</p> {itemd.used + " / " + itemd.total */}
                                    
                                </div>
                                </div> 
                                </div>
                                </Grid>
                                
                            ))
                            }
                            
                            </Grid>  
                            </div>
                            )}
                            
                          
                           
                           
                            
                           
                            </Section>
                        ))}

                 
                     </div>
            
                {this.state.waiting ? (
                    <div style={{textAlign:'center',justify:'center',alignSelf:'center'}}>
                       <h1>Obteniendo datos... {this.state.numberOn}</h1>
                       <CircularProgress color="inherit" />
                     </div>
                ) : null}
                
                  

               
                
            </div>
        )
    }

}


export default withStyles(styles)(Telinfo);



  /*
   {
            "icc" : "8952140061756034895", 
            "msisdn" : "4613471005", 
            "type" : "main",
            "alias" : "Programación Rodrigo"
        },
        {
            "icc" : "8952140061756035330", 
            "msisdn" : "5510021292", 
            "type" : "main",
            "alias" : "Arturo principal"
        },
        {
            "icc" : "8952140061756026008", 
            "msisdn" : "5628282323", 
            "type" : "sec",
            "alias" : "Oficina"
        },
        {
            "icc" : "8952140061756029358", 
            "msisdn" : "4428906005", 
            "type" : "main",
            "alias" : "Leonel"
        },
        

        //'4428950891' //'5628282323' //
        /* 
        Patrón 5628282323  8952140061756026008
        Hector 5628230800
        Leonel 4428906005 8952140061756029358
        Mio 4613473137  
        */
