import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo } from '../components/dataController';

import AppLayout from '../components/AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import ProjectCard from "../components/projectCard";
import AlertMessage from '../components/fields/AlertMessage';
import { main_subsections } from '../config.js'
import Section from '../components/Section';
import theme from '../theme.js';
import ButtonField from '../components/fields/ButtonField';
import AutoCompleteField from '../components/fields/AutoCompleteField';
import Telinfo from '../components/Telinfo';
import Facturacion from '../components/Facturacion';
import Servicios from '../components/Servicios';
import Cuenta from '../components/Cuenta';
import Tienda from '../components/Tienda';
import Carrito from '../components/Carrito';
import Recargas from '../components/Recargas';
import Consumos from '../components/Consumos';


const styles = theme => ({
  
})


var user = null;


class Dashboard extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
        dataRdy : false,
        dataUser : {},
        freeUnits : []
       
    } 

    static contextType = UserContext;


    
    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };
    

    componentDidMount(){
        /*this.setState({'waiting':true})
        this.context.login(()=>{
           // this.getUserInfo();
           this.setState({'waiting':false})
        }); */        
    }


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
    }


    render() {
        const { classes, history, session } = this.props;
        user = this.context.user;
        const data = this.state.data
        const { hash } = this.props.location
        const title = 'Ruta'


        

 
        return (
            <AppLayout logout={true} title={title} tabs={main_subsections} tab={hash != '' ? hash : '#telinfo'} >

{/*
<a href="https://api.whatsapp.com/send?phone=5215568269633&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n" style={{ position:'fixed',
                        width:60,
                        height:60,
                        bottom:40,
                        right:40,
                        
                        color:'#FFF',
                        borderRadius:50,
                        textAlign:'center',
                       
                        }} target="_blank">
                    <img src="whats.png" style={{width:60,height:60}} /> 
                </a>
                    */}
                
                { hash == '#telinfo' || hash == '' ?
                    <div id='#telinfo'>
                        <div title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Telinfo />
                                </Grid> 
                            </Grid>
                        </div>
                        
                    </div>
                : null }
                { hash == '#facturacion' ?
                        <Section title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Facturacion />
                                </Grid> 
                            </Grid>
                        </Section>
                : null }
                 { hash == '#servicios' ?
                        <div title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Servicios />
                                </Grid> 
                            </Grid>
                        </div>
                : null }
                { hash == '#cuenta' ?
                        <div title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Cuenta/>
                                </Grid> 
                            </Grid>
                        </div>
                : null }
                 { hash == '#consumos' ?
                        <Section title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Consumos/>
                                </Grid> 
                            </Grid>
                        </Section>
                : null }
                { hash == '#tienda2' ?
                        <Section title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Tienda />
                                </Grid> 
                            </Grid>
                        </Section>
                : null }
                { hash == '#carrito2' ?
                        <Section title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Carrito />
                                </Grid> 
                            </Grid>
                        </Section>
                : null }
                { hash == '#recargas' ?
                        <Section title='' >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Recargas />
                                </Grid> 
                            </Grid>
                        </Section>
                : null }
               
            </AppLayout>
        )
    }

}


export default withStyles(styles)(Dashboard);